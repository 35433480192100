import { MapContainer, TileLayer, LayersControl, GeoJSON, ScaleControl, ZoomControl} from 'react-leaflet'
import type { GeoJsonObject } from 'geojson'
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import chroma from "chroma-js";
import GeoRasterLayer from "./GeoRasterLayer";
import LegendControl from './LegendControl';
import LegendColorbar from './LegendColorbar';
import LogoControl from './LogoControl';
import logo from '../logo.svg';
import turbines from "./turbines.json";

const wind_speed_limits: [number, number] = [5, 15]
const wind_speed_deficit_limits: [number, number] = [-6, 6]

const colormap = (cmapName: string, vmin: number, vmax: number, scale: number, value: Array<number>) => {
  if (isNaN(value[0])) return "rgba(0, 0, 0, 0)";
  return colorscales[cmapName]((value[0] / scale - vmin) / (vmax - vmin)).alpha(0.8).css();
};

export const colorscales = {
  greys: chroma.scale("Greys").domain([1, 0]),
  viridis: chroma.scale("Viridis"),
  RdBu: chroma.scale("RdBu"),
};

const setIcon = ({ properties }, latlng) => {
  return L.circleMarker(latlng)
};

export default function Map() {
  return (
      <MapContainer center={[53.5, 4]} zoom={7} style={{ height: "100vh" }} zoomControl={false}>
        <LogoControl position="topleft" logo={logo} />
        <ScaleControl position="bottomleft" />
        <ZoomControl position="bottomleft" />
        <LegendControl position="bottomright" >
          <LegendColorbar
            limits={wind_speed_limits}
            unit="m/s"
            pixelValuesToColorFn={(v) => colormap("viridis", wind_speed_limits[0], wind_speed_limits[1], 1., [v as any])}
          />
          <LegendColorbar
            limits={wind_speed_deficit_limits}
            unit="m/s"
            pixelValuesToColorFn={(v) => colormap("RdBu", wind_speed_deficit_limits[0], wind_speed_deficit_limits[1], 1., [v as any])}
          />
        </LegendControl>
        <LayersControl position="topright">
          <LayersControl.Overlay name="Average windspeed 2050" checked>
            <GeoRasterLayer
                paths={[window.location.href + "wind_speed_2050_epsg_3857.tif"]}
                selected={true}
                resolution={64}
                zIndex={12}
                pixelValuesToColorFn={(v) => colormap("viridis", wind_speed_limits[0], wind_speed_limits[1], 1., v as any)}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Average windspeed 2020">
            <GeoRasterLayer
                paths={[window.location.href + "wind_speed_2020_epsg_3857.tif"]}
                selected={true}
                resolution={64}
                zIndex={13}
                pixelValuesToColorFn={(v) => colormap("viridis", wind_speed_limits[0], wind_speed_limits[1], 1., v as any)}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Average windspeed deficit 2050 vs 2020 ">
            <GeoRasterLayer
                  paths={[window.location.href + "wind_speed_deficit_epsg_3857.tif"]} 
                  selected={true}
                  resolution={64}
                  zIndex={14}
                  pixelValuesToColorFn={(v) => colormap("RdBu", wind_speed_deficit_limits[0], wind_speed_deficit_limits[1], 1., v as any)}
            />
          </LayersControl.Overlay>
          <LayersControl.BaseLayer  name="ESRI World Imagery" checked>
            <TileLayer
              attribution="Powered by <a href='https://www.esri.com'>Esri</a> | DigitalGlobe, GeoEye, i-cubed, USDA, USGS, AEX, <br/> Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community"
              url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=${process.env.NEXT_PUBLIC_ESRI_TOKEN}`}
            />
          </LayersControl.BaseLayer >
          <LayersControl.BaseLayer  name="OpenStreetMap">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer >

          <LayersControl.Overlay name="Turbines" checked>
            <GeoJSON data={turbines as GeoJsonObject} style={{color: "#FF14FF"}}/>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
  );
}