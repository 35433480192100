import * as React from "react";
import { LayerGroup } from "react-leaflet"; // Marker, Popup,
import type { LeafletContextInterface } from "@react-leaflet/core";
import { createPathComponent } from "@react-leaflet/core";
import parseGeoraster from "georaster";
import type { GeoRaster, GeoRasterLayerOptions } from "georaster-layer-for-leaflet";
import GeoRasterLayerForLeaflet from "georaster-layer-for-leaflet";

const GeoRasterComponent = createPathComponent(
    (options: GeoRasterLayerOptions, context: LeafletContextInterface) => ({
        instance: new GeoRasterLayerForLeaflet(options),
        context,
    }),
    (instance, props, prevProps) => {
        if (instance && prevProps?.pixelValuesToColorFn !== props?.pixelValuesToColorFn) {
            instance.updateColors(props.pixelValuesToColorFn);
        }
    },
);

const useGeoraster = (paths: string[], selected: boolean) => {
    const [georasters, setGeoraster] = React.useState<GeoRaster[]>();

    React.useEffect(() => {
        if (selected) {
            const promises = paths.map((path) => parseGeoraster(path));
            Promise.all([...promises]).then((res: GeoRaster[]) => {
                setGeoraster(res);
            });
        }
    }, [paths, selected]);

    return georasters;
};

type Props = {
    paths: string[];
    selected: boolean;
} & Omit<GeoRasterLayerOptions, "georaster" | "georasters">;

function GeoRasterLayer({ paths, selected, ...options }: Props): React.ReactElement | null {
    const georasters = useGeoraster(paths, selected);

    return <LayerGroup>{georasters ? <GeoRasterComponent {...options} georasters={georasters} /> : null}</LayerGroup>;
}

export default GeoRasterLayer;
