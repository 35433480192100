import { ReactNode } from "react";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
    bottom: "leaflet-bottom",
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
    top: "leaflet-top",
};

type LegendControlProps = {
    position: string;
    children?: ReactNode[];
};

function LegendControl({ position, ...props }: LegendControlProps) {
    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

    return props?.children?.some(Boolean) ? (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar" style={{background: "white", marginBottom: "50px"}}>{props?.children}</div>
        </div>
    ) : null;
}

export default LegendControl;
