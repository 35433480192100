import React, { Dispatch, useState } from "react";
import PreventMapControl from "./PreventMapControl";

export type OnChangeFn = (limits: number[]) => undefined;

interface LegendColorbarForm {
    min?: string;
    max?: string;
}

type LegendColorbarProps = {
    limits: [number, number];
    unit: string;
    pixelValuesToColorFn?: (v: number) => {};
    onChange?: Dispatch<[number, number]>;
};

function LegendColorbar({ limits, unit, pixelValuesToColorFn = () => null, onChange = () => null }: LegendColorbarProps) {
    const [isScaleModalOpen, setScaleModalOpen] = useState(false);
    const [min, max] = limits;

    const interval: number = (max - min) / 9;
    const values = Array.from({ length: 10 })
        .map((_, index) => parseFloat((min + index * interval).toFixed(1)))
        .reverse();

    console.log(values)

    const colors = values.map(pixelValuesToColorFn);
    console.log(colors)


    const gradient: string = `linear-gradient(to bottom, ${colors.join(", ")})`;

    return (
        <PreventMapControl>
            <div className="row" style={{width: "100px", textAlign: "right", margin: "5px"}}>
                <div
                    title="Colorbar legend, click to open settings"
                    className="column"
                    style={{background: gradient}}
                />
                <div className="column">
                    {values.map((tick, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index}>
                                <span className="font-medium">{tick}</span> {unit}
                            </div>
                        );
                    })}
                </div>
            </div>
        </PreventMapControl>
    );
}

export default LegendColorbar;
