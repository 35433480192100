import React, { ReactNode, useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import { DomEvent } from "leaflet";

type PreventMapControlProps = {
    children: ReactNode;
};

/**
 * Disables Leaflet map controls temporarily when using for example an interactive legend
 * Otherwise, when for ex. focusing on an input, the map will zoom in
 */
function PreventMapControl({ children }: PreventMapControlProps) {
    const controlRef = useRef();
    const map = useMap();
    useEffect(() => {
        if (controlRef) {
            DomEvent.addListener(controlRef.current, "mouseenter", () => {
                map.dragging.disable();
                map.doubleClickZoom.disable();
            });
            DomEvent.addListener(controlRef.current, "mouseleave", () => {
                map.dragging.enable();
                map.doubleClickZoom.enable();
            });
        }
    });

    return <div ref={controlRef}>{children}</div>;
}

export default PreventMapControl;
